<template>
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.25 11.8753H17.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.25 11.8753H17.125" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.125 15.0413H5.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.125 15.0413H5.25" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.375 8.70833H17.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.375 8.70833H17.125" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.125 5.54134H12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.125 5.54134H12.375" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.625 7.12533H8.41667C8.85389 7.12533 9.20833 7.47977 9.20833 7.91699C9.20833 8.35422 8.85389 8.70866 8.41667 8.70866C7.97944 8.70866 7.625 8.35422 7.625 7.91699V5.93783C7.625 5.06337 8.33388 4.35449 9.20833 4.35449" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.625 7.12533H8.41667C8.85389 7.12533 9.20833 7.47977 9.20833 7.91699C9.20833 8.35422 8.85389 8.70866 8.41667 8.70866C7.97944 8.70866 7.625 8.35422 7.625 7.91699V5.93783C7.625 5.06337 8.33388 4.35449 9.20833 4.35449" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.875 7.12533H3.66667C4.10389 7.12533 4.45833 7.47977 4.45833 7.91699C4.45833 8.35422 4.10389 8.70866 3.66667 8.70866C3.22944 8.70866 2.875 8.35422 2.875 7.91699V5.93783C2.875 5.06337 3.58388 4.35449 4.45833 4.35449" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.875 7.12533H3.66667C4.10389 7.12533 4.45833 7.47977 4.45833 7.91699C4.45833 8.35422 4.10389 8.70866 3.66667 8.70866C3.22944 8.70866 2.875 8.35422 2.875 7.91699V5.93783C2.875 5.06337 3.58388 4.35449 4.45833 4.35449" stroke="white" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  name: "BlockquoteIcon"
}
</script>
