<template>
  <div id="chat-container">
    <!-- Header Section -->
    <header class="header">
      <img :src="duotalkLogo" alt="Duotalk Logo" class="header-logo" />
      <div class="company-info">
        <h1 class="company-name">Duotalk</h1>
        <p class="company-details">
          Plataforma de atendimento com Inteligência Artificial
        </p>
        <p class="company-details">
          <a href="https://www.duotalk.com.br">www.duotalk.com.br</a>
        </p>
      </div>
    </header>

    <!-- Contact Info Section -->
    <div class="contact-info">
      <div class="profile-image">
        <!-- <template v-if="contactObject.profilePic">
          <img
            :src="contactObject.profilePic"
            :alt="`Foto de perfil de ${contactObject.name}`"
          />
        </template>
        <template v-else> -->
        <span>{{ contactObject.name.charAt(0) }}</span>
      </div>
      <div class="contact-details">
        <h3>{{ contactObject.name }}</h3>
        <p>{{ formattedPhoneNumber }}</p>
        <a :href="`mailto:${contactObject.email}`">{{ contactObject.email }}</a>
        <p>ID: {{ contactObject.chatID }}</p>
      </div>
    </div>

    <!-- Messages -->
    <div
      v-for="(message, index) in formattedMessages"
      :key="index"
      class="message-wrapper"
    >
      <!-- Spacer above the message box -->
      <div class="spacer"></div>

      <!-- Message Box -->
      <div
        :class="{
          message: true,
          user: message.author === 'me',
          bot: message.author === 'assistant' || message.author === 'system',
          log: message.author === 'log',
          'other-author':
            message.author !== 'me' &&
            message.author !== 'assistant' &&
            message.author !== 'system' &&
            message.author !== 'log' &&
            message.author !== 'sentimentAnalysis' &&
            message.author !== 'chatSummary',
        }"
      >
        <!-- Normal User Messages -->
        <div v-if="message.author === 'me'">
          <div class="message-info">
            <span class="name">{{ contactObject.name }}:</span>
          </div>
          <div class="message-content">
            <span v-if="message.data.mimetype === 'video/mp4'"
              >Vídeo Recebido:
              <a
                :href="`${API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`"
                target="_blank"
                class="file-link"
                >{{ message.data.file }}</a
              ></span
            >
            <span
              v-else-if="
                message.data.mimetype === 'image/jpeg' ||
                message.data.mimetype === 'image/png'
              "
              >Imagem Recebida:
              <a
                :href="`${API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`"
                target="_blank"
                class="file-link"
                >{{ message.data.file }}</a
              ></span
            >
            <span v-else-if="message.data.mimetype === 'application/pdf'"
              >Arquivo Recebido:
              <a
                :href="`${API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`"
                target="_blank"
                class="file-link"
                >{{ message.data.file }}</a
              ></span
            >
            <span v-else-if="message.data.mimetype === 'audio/ogg; codecs=opus'"
              >Áudio Recebido:
              <a
                :href="`${API_URL}p/chat/redirFile/${message._id}/${message.data.filename}?acc=${message.acc}&token=${message.authToken}`"
                target="_blank"
                class="file-link"
                >{{ message.data.file }}</a
              ></span
            >
            <span v-else>{{ message.data.text }}</span>
            <span class="timestamp">{{ message.formattedTimestamp }}</span>
          </div>
        </div>

        <!-- Assistant or System Messages -->
        <div
          v-else-if="
            message.author === 'assistant' || message.author === 'system'
          "
        >
          <div class="message-info">
            <span class="name">Duotalk Bot</span>
          </div>
          <div v-if="message.data.fileUrl" class="message-content">
            <a
              :href="message.data.fileUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="file-link"
            >
              {{ message.data.fileUrl }}
            </a>
          </div>
          <div
            class="message-content"
            :class="{ long: message.isLong }"
            v-html="message.formattedText"
          ></div>
          <div v-if="message.suggestions">
            <div class="suggestions">
              <span
                v-for="(suggestion, index) in message.suggestions"
                :key="index"
              >
                - {{ suggestion }}<br />
              </span>
            </div>
          </div>
          <div>
            <span class="timestamp">{{ message.formattedTimestamp }}</span>
          </div>
        </div>

        <!-- Log Messages -->
        <div v-else-if="message.author === 'log'">
          <div class="message-content log-content">
            <p>{{ message.data.text }}</p>
            <p class="timestamp">{{ message.formattedTimestamp }}</p>
          </div>
        </div>

        <!-- Chat Summary and Sentiment Analysis -->
        <div
          v-else-if="
            message.author === 'chatSummary' ||
            message.author === 'sentimentAnalysis'
          "
        >
          <div class="gpt-content">
            <p class="title">
              {{
                message.author === "chatSummary"
                  ? "Resumo da Conversa"
                  : "Análise de Sentimento"
              }}
            </p>
            <p>{{ message.data.text }}</p>
            <p class="timestamp">{{ message.formattedTimestamp }}</p>
          </div>
        </div>

        <!-- Other Authors -->
        <div v-else>
          <div class="message-info">
            <span class="name">{{ message.authorName }}</span>
          </div>
          <div
            class="message-content"
            :class="{ long: message.isLong }"
            v-html="message.formattedText"
          ></div>
          <div>
            <span class="timestamp">{{ message.formattedTimestamp }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import duotalkLogo from "../../../assets/images/duotalk-logo.png";
import dateFormatter from "@/helpers/dateFormatter";

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    messageListHistory: {
      type: Array,
    },
    API_URL: {
      type: String,
    },
  },
  data() {
    return {
      duotalkLogo,
    };
  },
  computed: {
    formattedMessages() {
      const sortedMessages =
        this.messageListHistory || this.$store.state.chat.messageList;

      return sortedMessages.map((message) => ({
        ...message,
        formattedTimestamp: dateFormatter(message.timestamp),
        formattedText: this.formatBoldText(message.data.text),
      }));
    },
    contactObject() {
      return this.contact;
    },

    formattedPhoneNumber() {
      if (this.contactObject.phone) {
        return this.formatPhoneNumber(this.contactObject.phone);
      }

      return "";
    },
  },
  methods: {
    formatBoldText(text) {
      if (!text) return ""; // Return empty string if text is null or undefined

      // Apply replacements conditionally based on matching patterns
      if (/\*(.*?)\*/.test(text)) {
        text = text.replace(/\*(\S(.*?)\S)\*/g, "<strong>$1</strong>"); // Bold formatting
      }

      if (/\n/.test(text)) {
        text = text.replace(/\n/g, "<br>"); // Line breaks
      }

      if (/(https?:\/\/[^\s]+)/.test(text)) {
        text = text.replace(
          /\b(https?:\/\/[^\s]+)/g,
          '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>' // Links
        );
      }
      return text;
    },

    formatPhoneNumber(phoneNumber) {
      let phoneStr = phoneNumber.toString();
      if (phoneStr.length < 13) {
        return `+${phoneStr.slice(0, 2)} (${phoneStr.slice(
          2,
          4
        )}) ${phoneStr.slice(4, 8)}-${phoneStr.slice(8)}`;
      }
      return `+${phoneStr.slice(0, 2)} (${phoneStr.slice(
        2,
        4
      )}) ${phoneStr.slice(4, 9)}-${phoneStr.slice(9)}`;
    },
  },
};
</script>

<style scoped>
#chat-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.spacer {
  height: 10px; /* Adjust height as needed */
  background-color: #ffffff; /* White background for the spacer */
}

.message {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  page-break-inside: avoid;
  color: #000;
}

.user {
  align-self: flex-start; /* User messages align to the left */
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.bot {
  align-self: flex-end; /* Bot messages align to the right */
  background-color: rgb(211, 209, 235);
  border: 1px solid #b3c4ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  page-break-inside: avoid;
}

.other-author {
  align-self: flex-end;
  background-color: #e0e7ff;
  border: 1px solid #b3c4ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  page-break-inside: avoid;
}

.message-info {
  font-size: 0.9rem;
  color: #000;
  margin-bottom: 5px;
  page-break-inside: avoid;
}

.message-content {
  font-size: 1rem;
  color: #000;
}

.message-content.long {
  background-color: #f0f4ff;
  padding: 10px;
  border-radius: 10px;
  font-size: 1rem;
}

.name {
  font-weight: bold;
  margin-right: 5px;
  color: #000;
}

.timestamp {
  color: #5c5a5a;
  font-size: 0.8rem;
}

.message-content p {
  margin: 5px 0;
}

.message-content strong {
  color: #000;
  font-weight: bold;
}

.message-content .warning {
  color: #ffcc00;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-logo {
  width: 120px;
  height: auto;
}

.company-info {
  text-align: right;
  font-family: Arial, sans-serif;
}

.company-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.company-details {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

/* Add styles for log messages */
.message.log {
  align-self: center;
  background-color: #f7e4a6;
  color: #666;
  border-radius: 15px;
  padding: 10px 20px;
  max-width: 70%;
  text-align: center;
  font-style: italic;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  position: relative;
  z-index: 1;
}

.log-content {
  font-size: 0.9rem;
  color: #666;
}

.timestamp {
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 5px;
  display: block;
}

.user-info {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  font-family: Arial, sans-serif;
}

.user-name {
  font-weight: bold;
}

.user-phone {
  color: #666;
}

.message-image {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 5px;
}

.file-link {
  color: #4a90e2;
  text-decoration: underline;
  word-break: break-all; /* Break long URLs if necessary */
}

.contact-info {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: relative; /* Position the chat content relative */
  z-index: 1; /* Bring the content above the background */
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  margin-right: 15px;
}

.contact-details h3 {
  font-size: 18px;
  margin: 0;
  font-weight: normal;
}

.contact-details p,
.contact-details a {
  font-size: 14px;
  margin: 2px 0;
  color: #3a3a3a;
}

.contact-details a {
  color: #3a87ad;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

.gpt-content {
  align-self: flex-end;
  background-color: #ffffe5; /* Light yellow background */
  border: 2px solid #ffcc00; /* Yellow border */
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.gpt-content .title {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #000; /* Yellow text color */
  font-size: 16px;
  margin-bottom: 8px;
}

.gpt-content .title::before {
  content: "📋"; /* Icon similar to the one in the image */
  font-size: 18px;
  margin-right: 6px;
}

.gpt-content p {
  margin: 0;
  color: #000; /* Dark text for readability */
}

.gpt-content .timestamp {
  font-size: 12px;
  color: #888; /* Gray color for the timestamp */
  margin-top: 10px;
  text-align: left;
}
</style>
