<template>
  <div class="relative w-10 h-10">
    <svg class="absolute w-full h-full" viewBox="0 0 32 32">
      <!-- White background circle -->
      <circle
        cx="16"
        cy="16"
        r="14"
        fill="rgba(255, 255, 255, 0.8)"
        stroke-width="4"
      />
      <circle
        class="opacity-25"
        cx="16"
        cy="16"
        r="14"
        fill="none"
        stroke="currentColor"
        stroke-width="4"
      />
      <circle
        class="opacity-75"
        cx="16"
        cy="16"
        r="14"
        fill="none"
        stroke="currentColor"
        stroke-width="4"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference - (circumference * progress) / 100"
        style="transition: stroke-dashoffset 0.3s ease-in-out"
      />
    </svg>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs text-black font-bold"
    >
      {{ progress }}%
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  computed: {
    circumference() {
      return 2 * Math.PI * 14; // r = 14
    },
  },
};
</script>

<style scoped>
/* Add any additional styling if needed */
</style>
